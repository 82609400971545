import React from "react";
import StateSelect from '../components/StateSelect';

function Homepage({setForecast, setZoneName, selectedState, setSelectedState}) {

    return(
        <div className="homeDiv">
            <h1>Weather App</h1>
            <StateSelect setForecast = {setForecast} setZoneName={setZoneName} selectedState={selectedState} setSelectedState={setSelectedState}/>
        </div>
    )
}

export default Homepage