import './App.css';
import React from 'react';
import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import ForecastPage from './pages/ForecastPage';

function App() {

  const [forecast, setForecast] = useState([]);
  const [zoneName, setZoneName] = useState('');
  const [selectedState, setSelectedState] = useState("");

  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path='/' exact element={<Homepage setForecast={setForecast} setZoneName={setZoneName} selectedState={selectedState} setSelectedState={setSelectedState} />} />
            <Route path='/forecast' element={<ForecastPage forecast={forecast} zoneName={zoneName} selectedState={selectedState} />}/>
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
