import React from "react";

function Zone({zone, getForecast, setZoneName}) {

    const buttonFunction = () => {
        getForecast(zone.id)
        setZoneName(zone.name)
    }
    return(
        <tr>
            <td className="zone">{zone.name}</td>
            <td className="zone"><button onClick={() => buttonFunction()}>View Forecast</button></td>
        </tr>
    )
}

export default Zone