import React from "react";

function Forecast({period}) {

    return(
        <tr>
            <td className="period">{period.name}</td>
            <td className="detailedForecast">{period.detailedForecast}</td>
        </tr>
    )
}

export default Forecast 