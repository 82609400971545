import React from 'react';
import { useEffect, useState } from "react";
import Forecast from '../components/Forecast'

function ForecastPage({forecast, zoneName, selectedState}) {
    
    const storedForecast = JSON.parse(localStorage.getItem('forecast'));
    const storedZoneName = localStorage.getItem('zoneName');
    const storedSelectedState = localStorage.getItem('selectedState');

    const [usedForecast] = useState(forecast.length === undefined ? forecast: storedForecast);
    const [usedZoneName] = useState(zoneName ? zoneName : storedZoneName);
    const [usedSelectedState]= useState(selectedState ? selectedState : storedSelectedState);

    useEffect(() => {
        if (forecast.length === undefined) {
          localStorage.setItem('forecast', JSON.stringify(forecast));
          localStorage.setItem('zoneName', zoneName);
          localStorage.setItem('selectedState', selectedState);
        }
      }, [forecast, zoneName, selectedState]);

    return(
        <table className='forecastTable'>
            <caption>Weekly Weather Forecast for {usedZoneName}, {usedSelectedState}</caption>
            <tbody>
                {usedForecast.properties.periods.map((period, i) => <Forecast
                    period = {period}
                    key={i} />)}
            </tbody>
        </table>
    )
}

export default ForecastPage