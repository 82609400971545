import React from 'react';
import Zone from './Zone.js';

function DisplayZones({zones, getForecast, setZoneName}) {
     
    return(
        <table className='zonesTable'>
            <tbody>
                {
                    zones?.features?.map((zone, i) => 
                        <Zone
                            setZoneName={setZoneName}
                            zone = {zone.properties}
                            getForecast = {getForecast}
                            key={i} 
                        />
                    )
                }
            </tbody>
        </table>
    )
} 

export default DisplayZones