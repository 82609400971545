import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DisplayZones from './DisplayZones';

function StateSelect({setForecast, setZoneName, selectedState, setSelectedState}) {

    const [zones, setZones] = useState([]);

    const states_abv = [ 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
    'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
    'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
    'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
    'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];

    const navigate = useNavigate();

    const getZones = async () => {

        const response = await fetch(`https://api.weather.gov/zones?area=${selectedState}&type=forecast`, {
            method: 'GET',
            headers: {
                'User-Agent': 'test@test.com'
            }
        });
        const data = await response.json();

        if (response.status === 200) {
            setZones(data);
        };
    };

    const getForecast = async zoneId => {

        const response = await fetch(`https://api.weather.gov/zones/land/${zoneId}/forecast`, {
            method: 'GET',
            headers: {
                'User-Agent': 'test@test.com'
            }
        });
        const data = await response.json();

        if (response.status === 200) {
            setForecast(data);
            navigate('/forecast');
        };
    }

    return(
        <div>
            <span className='stateSelection'>Select a state:  </span>
            <select onChange={e => setSelectedState(e.target.value)}>
                <option>--State--</option>
                {states_abv.map((state, i) => <option key={i}>{state}</option>)}
            </select>
            <button onClick={() => getZones()}>Go</button>
            <DisplayZones zones={zones} getForecast={getForecast} setZoneName={setZoneName} />
        </div>

    )
}

export default StateSelect